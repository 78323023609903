// vue
import { ref, watch, computed, reactive } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@store/bots';
import { useRefs } from '@store/ts/refs';

// dropzone
import { useDropzone } from 'vue3-dropzone';

// services
import BotsService from'@services/bots';
import WalletsService from '@services/walletsService';
import ApiKeysService from'@services/apiKeysService';
import ExchangesService from '@services/exchangesService';

// shared
import { CRUD_PAGER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();

    // i18n
    const { t } = useI18n();

    // vars
    const init = ref(false);
    const file = reactive({
        info: null,
        result: null,
    });
    const walletsRefs = ref();
    const apiKeysRefs = ref();
    const dataForm = reactive({});
    const exchangeInfo = ref();
    const invalidConfig = ref(false);

    const loadings = reactive({
        main: false,
        buttonCreate: false,
    });

    // computeds
    const isWalletSelected = computed(() => dataForm?.wallet.value !== -1);
    const isApiKeySelected = computed(() => dataForm?.api_key.value !== -1);
    const pairSymbols = computed(() => String(dataForm.pair).split('/').map(s => s.trim()));
    const isExchangeCoin = computed(() => exchangeInfo.value?.extra.coin == 1);
    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange)?.futures);
    const canCreateBot = computed(() => dataForm?.name?.value.trim().length > 0 && isWalletSelected.value && isApiKeySelected.value);
    const coinSymbolForWallet = computed(() => {
        const [fSymbol, sSymbol] = pairSymbols.value;
        if (isExchangeCoin.value) return fSymbol;
        if (isExchangeFutures.value) return sSymbol;
        return dataForm.algo === 'long' ? sSymbol : (dataForm.algo === 'short' ? fSymbol : null);
    });
    const pairWalletsRefs = computed(() => {
        if (!walletsRefs.value || dataForm.algo == -1) return [];
        return walletsRefs.value.filter(w => w.currency == coinSymbolForWallet.value);
    });
    
    watch(() => props.show, v => {
        if (!v) {
            clearData();
        }
    });

    const onDropAccepted = $event => {
        clearData();
        invalidConfig.value = false;
      
        setTimeout(() => {
            file.info = $event[0];
            const fr = new FileReader();

            fr.onload = async (event) => {
                try {
                    file.result = JSON.parse(event.target.result);
                    console.log(file.result);
                    prepare(file.result);
                } catch (e) {
                    console.log(e);
                    invalidConfig.value = true;
                }
            };
            
            fr.readAsText($event[0]);
        }, 200);
    };

    const clearData = () => {
        file.info = null;
        file.result = null;
        init.value = false;
    };

    const prepare = async res => {
        init.value = false;

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value: '',
                    title: bots.localization['bot_name_f'],
                    dataHelp: bots.help['bot_name'],
                    placeholder: bots.localization['bot_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            }  else if (key === 'api_key') {
                dataForm[key] = {
                    value: '-1',
                    title: bots.localization['apikey_f'],
                    dataHelp: bots.help['api_key'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    buttonLabel: bots.localization['bots_create_new_api_key'],
                    customField: true,
                };
            }  else if (key === 'wallet') {
                dataForm[key] = {
                    value: '-1',
                    title: bots.localization['depo_f'],
                    dataHelp: bots.help['depo'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    buttonCreateLabel: bots.localization['bots_create_new_wallet'],
                    buttonCheckLabel: bots.localization['bots_check_balance'],
                    buttonCheckDisabled: computed(() => !isWalletSelected.value),
                    buttonEditLabel: bots.localization['bots_edit_wallet'],
                    buttonEditDisabled: computed(() => !isWalletSelected.value),
                    walletsRefs: computed(() => walletsRefs.value),
                    customField: true,
                };
            }  else {
                dataForm[key] = value;
            }
        }

        loadings.main = true;
        await Promise.allSettled([
            getWalletsList(),
            getApiKeysList(),
            exchangeGetFullInfo(),
        ]);
        loadings.main = false;

        console.log('walletsRefs', walletsRefs.value);
        console.log('apiKeysRefs', apiKeysRefs.value);

        init.value = true;
    };

    const getWalletsList = async () => {
        try {
            walletsRefs.value = ( await WalletsService.getWalletsList({
                exchanges: [dataForm.exchange],
                pairs: [dataForm.pair],
            }) ).data.records;

        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const getApiKeysList = async (id = dataForm.exchange) => {
        try {
            apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({
                exchanges: [id],
                statuses: [1],
            }) ).data.records;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const exchangeGetFullInfo = async (id = dataForm.exchange) => {
        try {
            exchangeInfo.value = ( await ExchangesService.getFullInfo(id) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const setApiKey = $event => {
        dataForm.api_key.value = $event.id;
        apiKeysRefs.value.push($event);
    };

    const setLoading = (key, v = true) => {
        const i = Object.keys(dataForm).findIndex(el => el === key);

        if (~i)
            Object.values(dataForm).slice(i).forEach(el => {
                if (typeof el === 'object')
                    el.loading = v;
            });
    };

    const addNewWallet = async $event => {
        setLoading('wallet');
        setLoading('api_key');

        delete bots.errorsForm.wallet;

        await getWalletsList();

        const i = walletsRefs.value.findIndex(({ id }) => id == $event.id);

        if (~i) {
            dataForm.wallet.value = +$event.id;
        }

        setLoading('wallet', false);
        setLoading('api_key', false);
    };

    const updateWallet = $event => {
        setLoading('wallet');
      
        const i = walletsRefs.value.findIndex(({ id }) => id == $event.id);

        if (~i)
            walletsRefs.value[i] = $event;

        setLoading('wallet', false);
    };

    const onApplyClicked = async () => {
        loadings.buttonCreate = true;

        const payload = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        delete payload.id;
        payload['_formPath'] = 'bots.addNew';

        try {
            bots.clearErrors();

            const result = await BotsService.addNew({
                pager: CRUD_PAGER_FACTORY(),
                records: [payload],
            });

            if (result.postMessages) {
                result.postMessages.forEach(el => {
                    gl.showNotification({
                        type: el.success ? 'success' : 'error',
                        msg: el.msg,
                    });
                });
            };

            if (!result.data.status) {
                if (Object.keys(result.data.errors_form['bots.addNew'].fields).length) {
                    bots.errorsForm = result.data.errors_form['bots.addNew'].fields;
                } else if (Object.keys(result.data.errors_form['bots.addNew']?.innerForms).length) {
                    bots.setInnerForms({
                        data: result.data.errors_form['bots.addNew'].innerForms,
                        startFilters: payload.start_filters,
                    });
                }
            } else {
                context.emit('update:show', false);
              
                const newBot = await BotsService.get({
                    pager: CRUD_PAGER_FACTORY({
                        id: [result.data.records[0].id],
                    }),
                });

                if (bots.dataTable?.records)
                    bots.dataTable.records.unshift(newBot.data.records[0]);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.buttonCreate = false;
    };

    const {
        getRootProps,
        getInputProps,
        ...rest } = useDropzone({ onDropAccepted });

    return {
        gl,
        bots,
        file,
        init,
        loadings,
        dataForm,
        canCreateBot,
        walletsRefs,
        apiKeysRefs,
        exchangeInfo,
        invalidConfig,
        pairWalletsRefs,
        coinSymbolForWallet,
        setApiKey,
        updateWallet,
        getRootProps,
        getInputProps,
        addNewWallet,
        onApplyClicked,
        ...rest,
    };

}